<template>
  <div v-if="!user.pers" class="row">
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1>
          {{ students }}
        </h1><span>
        <i class="fa fa-users"></i>
        طالب موجود</span>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1>
          {{ total }}
          ريال
        </h1><span>
        <i class="fa fa-usd"></i>
        اجمالي رصيد الطلاب</span>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1>
          {{ today }}
          ريال
        </h1><span>
        <i class="fa fa-calendar"></i>
        رصيد مسحوب اليوم</span>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div class="card card-body">
        <h1>
          {{ total_history }}
          ريال
        </h1><span>
        <i class="fa fa-usd"></i>
        اجمالي الرصيد المسحوب</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      user: JSON.parse(localStorage.getItem('user')),
      total: 0,
      students: 0,
      today: 0,
      total_history: 0
    }
  },
  created(){
    var g = this;
    this.loading = true
    $.post(api + '/user/general/stats', {
        jwt: this.user.jwt
    }).then(function(r){
        g.loading = false
        if(r.status != 100){
            alert(r.response)
        }else{
            g.total = r.response.total.toFixed()
            g.students = r.response.students.toFixed()
            g.today = r.response.today.toFixed()
            g.total_history = r.response.total_history.toFixed()
        }
    }).fail(function(){
        g.loading = false
    })
  }
}
</script>

<style>

</style>